.modal-dialog-email {
  max-width: 800px !important;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.icon-spin {
 animation: spinning 0.5s infinite alternate;
}

@keyframes spinning {
    0% {transform: rotate(0deg);}
    10% {transform: rotate(0deg);}
    50% {transform: rotate(360deg);}
    90% {transform: rotate(0deg);}
    100% {transform: rotate(0deg);}
}